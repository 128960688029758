.bloc-tabs {
  display: flex;
}

.tabs {
  font-family: "28 Days Later" !important;
  font-size: 2rem;
  color: #1c1c1c;
  font-weight: bold;
  padding: 15px;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  border-right: none;
  border-left: none;
  border-top: none;
  position: relative;
}

.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: white;
  border-bottom: 1px solid transparent;
  text-decoration: underline #d8a860;
  text-decoration-thickness: 5px;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.content-tabs {
  flex-grow : 1;
}

.content {
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}

.detailsList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detailsList--grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2vw;
}

.info--container{
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 3rem 10%;
}

.info--img{
  width: 30%;
  height: 100%;
}

.info--details{
  display: none;
  width: 70%;
  font-size: 1.5rem;
}

.info--details__title{
  padding: 1rem 0;
  text-align: center;
  color: #fff;
  background: #901818;
}

.info--details p{
  text-align: center;
  margin: 1rem 0;
}

.detailsList--title{
  text-align: center;
  font-size: 3rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 3rem 0;
  text-decoration: underline #d8a860;
  text-decoration-thickness: 5px;
}

.table-style  {
  font-size: 1.6rem;
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0 5px 50px rgba(0,0,0,0.15);
  margin: 0 auto;
  border: 1px solid #ddd;
}

.table-style thead tr {
  background-color: #901818;
  color: #fff;
  text-align: left;
}

.table-style th, .table-style td {
  padding: 15px 20px;
  min-width: 100px;
}

.table-style tbody tr:nth-child(even){
  background-color: #f3f3f3;
}

.info--container{
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 3rem auto;
}

.info--img{
  width: 0;
  height: 0;
}

.detailsList--title{
  text-align: center;
  font-size: 3rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 3rem 0;
  text-decoration: underline #d8a860;
  text-decoration-thickness: 5px;
}

.description--text{
  font-size: 1.8rem;
  text-align: justify;
  color: #1c1c1c;
  width: 70%;
  margin: 0 auto;
  line-height: 150%;
}

.videoPlayer{
  display: flex;
  position: relative;
  height: 75vh;
  width: 70%;
  margin: 0 auto 3rem auto;
}

@media screen and (max-width: 800px){

  .table-style{
    display: none;
  }

  .info--container{
    flex-wrap: wrap;
  }
  
  .info--img{
    width: 100%;
    height: 100%;
  }
  
  .info--details{
    display: block;
  }

  .videoPlayer{
    height: 300px;
    width: 100%;
    margin: 0 0 3rem 0;
  }
  
  .info--details{
    width: 100%;
    margin-top: 3rem;
  }

}