.actor {
  margin: 2%;
  width: 20%;
  position: relative;
}
.actor--img {
  width: 100%;
}
.actor--overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.actor--name {
  font-size: 2rem;
  color: white;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

@media screen and (max-width: 1000px){
    
  .actor{
    width: 40%;
  }

}

@media screen and (max-width: 600px){

  .actor{
    width: 70%;
  }

}
