.header {
  z-index: 1000;
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #901818;
  color: #fff;
  position: fixed;
  top: 0;
  /* padding: 0 1.5rem; */
}
.header h3 {
  font-weight: bold;
  font-size: 2.7rem;
}
.header--movie {
  color: #fff;
  margin-left: 2rem;
}
.header--heart {
  color: #d8a860;
  margin-right: 2rem;
  cursor: pointer;
}
.header--container {
  position: relative;
}
.header--badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 1.3rem;
  background: #1c1c1c;
  color: white;
  right: 2rem;
  bottom: 0.3rem;
  font-weight: bolder;
  font-size: 1.5rem;
}
