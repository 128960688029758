.searchBar--container {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #901818;
}
.searchBar {
  border-radius: 4rem;
  box-shadow: 0 0.6rem 0.8rem rgba(0, 0, 0, 0.15);
  background: #fff;
  transition: all 0.3s ease;
  width: 70%;
  height: 4rem;
  outline: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBar--input {
  font-size: 3rem;
  background: none;
  color: #5a6674;
  width: 90%;
  height: 100%;
  border: none;
  appearance: none;
  outline: none;
  padding: 0 1rem;
  text-align: center;
  font-family: "Wicked Scary Movie" !important;
  text-transform: uppercase;
}
.searchBar--input:focus {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
}
.searchBar--submit {
  outline: none !important;
  cursor: pointer;
  padding: 0 2rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon {
  align-items: flex-start;
  font-size: 3rem;
  color: #901818;
}
