.headerImg {
  margin-top: 8rem;
  width: 100%;
  height: 75rem;
  background-size: cover !important;
  background-position: center, center !important;
  position: relative;
}
.headerImg--overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  top: 20rem;
  width: 50%;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.headerImg--overlay__title {
  font-size: 3.5rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  /* border-bottom: 0.7rem solid #d8a860; */
  text-decoration: underline #d8a860;
  text-decoration-thickness: 7px;
  align-self: flex-start;
}
.headerImg--overlay__desc {
  font-size: 1.8rem;
  text-align: justify; 
  line-height: 2.3rem;
  font-weight: 100;
  padding-top: 2rem;
}

@media screen and (max-width: 900px){
    
  .headerImg--overlay{
    width: 80%;
  }

  .headerImg{
    display: flex;
    justify-content: center;
  }

}

@media screen and (max-width: 600px){
    
  .headerImg--overlay {
    top: 15rem;
    width: 100%;
  }

  .headerImg--overlay__desc {
    padding: 2rem 2rem 0 2rem;
  }

  .headerImg--overlay__title {
    padding: 0 2rem 0.5rem 2rem;
  }

}
