.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container--title {
  font-size: 3rem;
}

@media screen and (max-width: 1000px){
    
  .container--title {
    font-size: 2rem;
  }

}

@media screen and (max-width: 600px){
    
  .container--title {
    font-size: 1.7rem;
  }

}
