/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "28 Days Later";
  font-style: normal;
  font-weight: normal;
  src: local("28 Days Later"), url("28 Days Later.woff") format("woff");
}
@font-face {
  font-family: "Wicked Scary Movie";
  font-style: normal;
  font-weight: normal;
  src: local("Wicked Scary Movie"), url("WickedScaryMovie.woff") format("woff");
}

* {
  padding: 0;
  margin: 0;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
}
body {
  font-family:  'Arial Narrow', Arial, sans-serif;
  /* font-family: "Wicked Scary Movie", Helvetica, Arial, sans-serif !important;  */
}
div {
  overflow: hidden;
}
h3 {
  font-family: "28 Days Later" !important;
}
.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.fa-faSpinner {
  color: #a81818;
  margin: 10rem auto;
}
