.moviePlayer {
  margin-top: 8rem;
  width: 100%;
  height: calc(100vh - 8rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer--container{
  width: 70%;
  height: calc(100vh - 8rem);
  position: relative;
}

@media screen and (max-width: 800px){

  .moviePlayer{
    flex-direction: column;
  }

  .videoPlayer--container{
    width: 100%;
  }

}