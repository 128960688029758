.headerDetails {
  margin-top: 6.9rem;
  width: 100vw;
  height: 45rem;
  background: rgba(0, 0, 0, 0.94);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.headerDetails--poster {
  height: 100%;
  width: 25%;
}

.headerDetails--poster__img {
  width: 100%;
  height: 100%;
}

.headerDetails--container {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerDetails--container__title {
  margin: 2rem 3rem;
  font-size: 3.5rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  text-decoration: underline #d8a860;
  text-decoration-thickness: 5px;
  align-self: center;
}

.headerDetails--container__desc {
  margin: 0 3rem;
  font-size: 1.8rem;
  text-align: justify;
  line-height: 2.3rem;
  font-weight: 100;
  padding-top: 2rem;
}

.headerDetails--info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  margin-top: 4rem;
}

.badge-decoration {
  width: 20rem;
  height: 3.5rem;
  border: 1px solid #e28c09;
  color: white;
  background: #d8a860;
  position: absolute;
  right: -2.5rem;
  top: 5rem;
  transform: rotateZ(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: bold;
  overflow: hidden !important;
}

@media screen and (max-width: 1000px){

  .headerDetails--container {
    width: 75%;
  }

  .badge-decoration {
    right: -4.5rem;
  }

}

@media screen and (max-width: 600px){

  .headerDetails {
    height: auto;
    flex-direction: column;
  }

  .headerDetails--container {
    height: 80%;
    width: 100%;
  }
    
  .headerDetails--container__desc {
    font-size: 1.5rem;
  }

  .headerDetails--poster {
    width: 100%;
  }

  .badge-decoration {
    right: -4.5rem;
  }

}