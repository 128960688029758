.poster {
  margin: 2%;
  width: 20%;
  position: relative;
}
.poster--img {
  width: 100%;
  cursor: pointer;
}
.poster--overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 20%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.poster--icon {
  color: #d8a860;
  margin-right: 1rem;
}
.poster--icon:hover {
  cursor: pointer;
}
.poster--icon__not {
  color: white;
  margin-right: 1rem;
}
.poster--icon__not:hover {
  cursor: pointer;
}
.poster--overlay__text {
  margin: 0 auto;
  font-weight: bold;
  font-size: 2rem;
  color: white;
}

@media screen and (max-width: 1000px){
    
  .poster{
    width: 40%;
  }

}

@media screen and (max-width: 600px){
    
  .poster{
    width: 70%;
  }

}