.faqs {
	width: 100%;
    height: 85vh;
	max-width: 768px;
	margin: 8rem auto 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.faqs h3{
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    border-bottom: 1px solid transparent;
    text-decoration: underline #d8a860;
    text-decoration-thickness: 5px;
}

.faqs .faqs-flex{
    height: 100%;
    
}

.faqs .faq {
    font: normal 16px sans-serif;
	margin: 20px;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 20px;
	padding-right: 80px;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-25%);
	width: 15px;
	height: 15px;
	background-image: url('./add.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	transition: all 0.5s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}

@media screen and (max-width: 600px){

    .faqs .faq{
	    padding: 15px;
    }

    .faqs .faq .faq-question{
        font-size: 15px;
    }
  
}